import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import App from "App"
import HttpsRedirect from "react-https-redirect"
import { AppProvider } from "context"
import { StylesProvider } from "@material-ui/core/styles"

const rootElement = document.getElementById("root")

ReactDOM.render(
  <HttpsRedirect>
    <BrowserRouter>
      <AppProvider>
        <StylesProvider injectFirst>
          <App />
        </StylesProvider>
      </AppProvider>
    </BrowserRouter>
  </HttpsRedirect>,
  rootElement
)
