import React, {
  useEffect,
  useContext,
  useState,
} from "react"
import { useUsers, useAlerts } from "hooks"
import { useHistory } from "react-router-dom"
import {
  ButtonLoader,
  SortableTableHeader,
} from "components"
import {
  Paper,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from "@material-ui/core"
import FavoriteItem from "containers/favorites/FavoriteItem"

const TABLE_HEADERS = [
  {
    value: "first_name",
    label: "Full Name",
    sortable: true,
    hidden: false,
  },
  {
    value: "country",
    label: "Country",
    sortable: true,
    hidden: false,
  },
  {
    value: "positions",
    label: "Position",
    sortable: false,
    hidden: false,
  },
  {
    value: "preferred_foot",
    label: "Foot",
    sortable: true,
    hidden: false,
  },
  {
    value: "dob",
    label: "Date of Birth",
    sortable: true,
    hidden: false,
  },
  {
    value: "height",
    label: "HT",
    sortable: true,
    hidden: false,
  },
  {
    value: "weight",
    label: "Weight",
    sortable: true,
    hidden: false,
  },
  {
    value: "total_score",
    label: "Training",
    sortable: false,
    hidden: false,
  },
]

const FavoritesList = ({
  isLoading,
  users,
  handleSort,
  sortKey,
  sortDirection,
  handleClick,
  selectedIds,
  handleSelect,
  ...props
}) => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <div>
      <TableContainer
        component={Paper}
        elevation={0}
        className={classes.table}
      >
        <Table>
          <TableHead>
            <TableRow
              className={classes.tableHeaderRow}
            >
              {TABLE_HEADERS.filter(
                (h) => !h.hidden
              ).map((header, idx) => (
                <SortableTableHeader
                  key={idx}
                  handleSort={handleSort}
                  value={`users.${header.value}`}
                  sortable={header.sortable}
                  sortKey={sortKey}
                  sortDirection={sortDirection}
                >
                  {header.label}
                </SortableTableHeader>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, idx) => (
              <FavoriteItem
                key={idx}
                user={user}
                handleClick={handleClick}
                handleSelect={handleSelect}
                selectedIds={selectedIds}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default FavoritesList

const useStyles = makeStyles((theme) => ({
  caption: {
    fontWeight: 600,
  },
  table: {},
  tableHeaderRow: {
    backgroundColor: theme.palette.background.table,
  },
}))
