import React from "react"
import { AsyncAutosuggest } from "components"

const TeamAutosuggest = ({
  value,
  name = "team_id",
  label,
  position,
  handleChange,
  ...props
}) => (
  <AsyncAutosuggest
    noBorder
    name={name}
    label={label}
    value={value}
    position={position}
    displayField="name"
    remoteUrl="/api/v1/scout/teams"
    placeholder="SELECT TEAM ▼"
    handleChange={handleChange}
  />
)

export default TeamAutosuggest
