import React from "react"
import { Modal } from "components"
import { useAuth } from "hooks"
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { SETTINGS } from "lib/navigation"
import clsx from "clsx"

const SettingsModal = ({
  open,
  handleClose,
  ...props
}) => {
  const classes = useStyles()

  const { logoutUser } = useAuth()

  const handleClick = (item) => {
    switch (item.value) {
      case "terms":
        window.open(
          "https://ellevate-football.co.uk/terms-conditions",
          "_blank"
        )
        break
      case "privacy":
        window.open(
          "https://ellevate-football.co.uk/privacy-policy",
          "_blank"
        )
        break
      case "log_out":
        logoutUser()
        break
    }
  }

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={"SETTINGS"}
      maxWidth={"sm"}
      className={classes.dialogHeader}
      padding={0}
    >
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div className={classes.leftPanel}>
              <List className={classes.list}>
                <ListItem
                  button
                  className={clsx(classes.nav, {
                    [classes.selected]: true,
                  })}
                >
                  <ListItemText
                    primary={
                      <Typography variant="caption">
                        GENERAL
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </div>
          </Grid>
          <Grid item xs={8}>
            <List className={classes.list}>
              {SETTINGS.map((item, i) => (
                <ListItem
                  key={i}
                  button
                  disableGutters
                  className={classes.lineItem}
                  onClick={() => handleClick(item)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="caption">
                        {item.label}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

export default SettingsModal

const useStyles = makeStyles((theme) => ({
  root: {},
  list: {
    padding: 0,
  },
  nav: {
    padding: theme.spacing(0, 0, 0, 1),
  },
  lineItem: {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.common.tableBorder}`,
  },
  leftPanel: {
    height: "100%",
    minHeight: 260,
    borderRight: `1px solid ${theme.palette.common.tableBorder}`,
  },
  selected: {
    borderLeft: `6px solid ${theme.palette.primary.main}`,
  },
  dialogHeader: {
    backgroundColor: theme.palette.background.table,
  },
}))
