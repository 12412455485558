import React, { useEffect, useState } from "react"
import {
  alpha,
  Typography,
  InputBase,
  makeStyles,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

const Autosuggest = ({
  value,
  options,
  label,
  name,
  placeholder = "Select",
  multiselect = false,
  handleChange,
  handleInputChange,
  noBorder,
  ...props
}) => {
  const classes = useStyles({
    noBorder,
  })

  const [selected, setSelected] = useState({
    label: "",
    value: null,
  })

  const handleOnChange = (event, newValue) => {
    setSelected(newValue)
    handleChange({
      target: {
        name: name,
        value: newValue?.value,
      },
    })
  }

  useEffect(() => {
    if (typeof value != "object") {
      setSelected(
        options.find((o) => o.value == value)
      )
    } else {
      setSelected(value)
    }
  }, [value])

  return (
    <div className={classes.container}>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {label}
      </Typography>
      <Autocomplete
        multiple={multiselect}
        disableCloseOnSelect={multiselect}
        classes={{
          paper: classes.paper,
          option: classes.option,
          popperDisablePortal:
            classes.popperDisablePortal,
        }}
        value={selected}
        onChange={(event, newValue) => {
          handleOnChange(event, newValue)
        }}
        onInputChange={(event, newInputValue) => {
          handleInputChange &&
            handleInputChange(newInputValue)
        }}
        noOptionsText="No options"
        clearOnBlur
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => option?.label}
        getOptionSelected={(option, value) =>
          option?.label == value?.label
        }
        renderInput={(params) => (
          <InputBase
            placeholder={placeholder}
            ref={params.InputProps.ref}
            inputProps={{
              ...params.inputProps,
              autoComplete: "none",
            }}
            className={classes.inputBase}
          />
        )}
      />
    </div>
  )
}

export default Autosuggest

const useStyles = makeStyles((theme) => ({
  root: {},
  iconSelected: {
    height: 20,
    width: 20,
  },
  inputBase: {
    width: "100%",
    padding: 0,
    "& input": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      appearance: "none",
      padding: theme.spacing(0.5, 1),
      borderRadius: 4,
      transition: theme.transitions.create([
        "border-color",
        "box-shadow",
      ]),
      border: (props) =>
        props.noBorder ? null : "1px solid #ced4da",
      fontSize: 14,
    },
  },
  paper: {
    padding: 0,
    backgroundColor: theme.palette.common.grey,
    color: "#586069",
    fontSize: 14,
  },
  option: {
    fontSize: 14,
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 5,
    margin: 0,
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(1),
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  label: {
    marginBottom: 0,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
}))
