import React from "react"
import {
  Button,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { Notifications as NotificationIcon } from "@material-ui/icons"

const Notifications = ({
  notifications,
  onClick,
}) => {
  const classes = useStyles()
  const { pending_approvals } = notifications || {}
  return (
    <div>
      {pending_approvals > 0 && (
        <div className={classes.notifications}>
          <div clasName={classes.notification}>
            <IconButton size="small">
              <NotificationIcon
                className={classes.icon}
              />
            </IconButton>
            <Typography
              variant="caption"
              className={classes.notificationText}
            >
              NEW NOTIFICATIONS{" "}
            </Typography>
            <Typography variant="caption">
              - {pending_approvals} NEW PLAYER
              APPROVALS REQUIRE ACTION
            </Typography>
          </div>
          <div clasName={classes.action}>
            <Button onClick={onClick}>See All</Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Notifications

const useStyles = makeStyles((theme) => ({
  icon: {
    height: 18,
    width: 18,
    margin: theme.spacing(0, 2),
  },
  notifications: {
    height: 44,
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.main,
  },
  notification: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  notificationText: {
    fontWeight: "bold",
  },
}))
