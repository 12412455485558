import React, { useState } from "react"
import {
  CircularProgress,
  FormControl,
  alpha,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  InputBase,
  Typography,
} from "@material-ui/core"
import { Clear, Send } from "@material-ui/icons"
import { syntheticEvent } from "lib/utils"
import clsx from "clsx"
import { useStyles } from "./styles"

const TextInput = ({
  className,
  required,
  label,
  type,
  name,
  margin,
  value,
  multiline,
  handleChange,
  rows,
  placeholder,
  disabled,
  onKeyPress,
  autoComplete = "off",
  endIcon,
  isClearable,
  isLoading,
  noBorder,
  ...props
}) => {
  const classes = useStyles({
    noBorder,
  })

  const [error, setError] = useState(false)

  const handleClearClick = () => {
    let ev = syntheticEvent("", name)
    handleChange(ev)
  }

  const handleInputChange = (ev) => {
    let { name, value } = ev.target
    required && value === ""
      ? setError(true)
      : setError(false)
    handleChange(ev)
  }

  return (
    <FormControl
      fullWidth
      className={clsx(className, classes.root)}
    >
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.label}
      >
        {label}
      </Typography>
      <InputBase
        error={error}
        rows={rows}
        className={classes.inputBase}
        multiline={multiline}
        autoComplete="off"
        fullWidth
        type={type}
        name={name}
        margin={margin}
        disabled={disabled}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        variant="outlined"
        onChange={handleInputChange}
        value={value}
        endAdornment={
          endIcon && (
            <InputAdornment>{endIcon}</InputAdornment>
          )
        }
      />
    </FormControl>
  )
}

export default TextInput
