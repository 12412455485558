import React, { useState, useEffect } from "react"
import { alpha } from "@material-ui/core"
import PinInput from "react-pin-input"
import { syntheticEvent } from "lib/utils"
import { muiTheme } from "theme"
import {
  Box,
  Typography,
  createTheme,
} from "@material-ui/core"
const theme = createTheme(muiTheme)

const ReactPinInput = ({
  name,
  value,
  label,
  handleChange,
  length = 6,
}) => {
  const handlePinChange = (value, index) => {
    let ev = syntheticEvent(value, name)
    handleChange(ev)
  }

  return (
    <Box>
      {label && (
        <Box mb={1}>
          <Typography variant="body2">
            {label}
          </Typography>
        </Box>
      )}
      <PinInput
        length={length}
        initialValue={value}
        onChange={handlePinChange}
        type="numeric"
        inputMode="number"
        style={styles.root}
        inputStyle={styles.input}
        inputFocusStyle={{
          ...styles.input,
          ...styles.inputFocus,
        }}
        onComplete={handlePinChange}
        autoSelect={true}
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
      />
    </Box>
  )
}

export default ReactPinInput

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  input: {
    width: "100%",
    webkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
    borderRadius: 2,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.5, 1),
    height: 36,
    transition: theme.transitions.create([
      "border-color",
      "box-shadow",
    ]),
    border: `1px solid ${theme.palette.common.inputBorder}`,
    ...theme.typography.subtitle1,
  },
  inputFocus: {
    webkitAppearance: "none",
    MozAppearance: "none",
    border: `1px solid ${theme.palette.primary.light}`,
    boxShadow: `${alpha(
      theme.palette.primary.light,
      0.1
    )} 0 0 0 0.2rem`,
  },
}
