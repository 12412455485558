import React from "react"
import PropTypes from "prop-types"
import { Paper, makeStyles } from "@material-ui/core"

const RightPanel = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.paper}>{children}</div>
    </div>
  )
}

RightPanel.propTypes = {
  children: PropTypes.any,
}

export default RightPanel

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "100%",
    flexGrow: 1,
    overflow: "none",
  },
  paper: {
    background: theme.palette.common.white,
    padding: theme.spacing(0),
    height: "calc(100vh - 150px)",
  },
}))
