import React, { useContext, useState } from "react"
import { useAlerts, useAuth } from "hooks"
import { AppContext } from "context"
import { useHistory } from "react-router-dom"
import {
  Box,
  Button,
  Grid,
  Link,
  makeStyles,
} from "@material-ui/core"
import { AuthScreen, TextInput } from "components"

const ResetPassword = ({ ...props }) => {
  const [email, setEmail] = useState()

  const classes = useStyles()
  const { showAlertError, showAlertSuccess } =
    useAlerts()
  const { authenticated, currentUser } =
    useContext(AppContext)

  const history = useHistory()

  const { forgotPassword } = useAuth()

  const handleSubmit = async () => {
    try {
      await forgotPassword(email)
      history.push(`/verify_pin?email=${email}`)
      showAlertSuccess(
        "We have sent you password reset instructions"
      )
    } catch (e) {
      showAlertError(
        "A user with that email doesn't exist"
      )
    }
  }

  const handleChange = ({ target }) =>
    setEmail(target.value)

  return (
    <div>
      <AuthScreen
        title="Forgot Password"
        actions={
          <Link
            href="/login"
            className={classes.link}
          >
            Log In
          </Link>
        }
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <TextInput
              border
              fullWidth
              required={true}
              name="email"
              placeholder="ENTER YOUR EMAIL"
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Box my={2}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleSubmit}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </AuthScreen>
    </div>
  )
}

export default ResetPassword

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.common.white,
  },
}))
