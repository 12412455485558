import React from "react"
import { alpha, makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  root: {},
  inputBase: {
    width: "100%",
    "& input, & textarea": {
      webkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      borderRadius: 4,
      padding: theme.spacing(0.5, 1),
      minHeight: 20,
      transition: theme.transitions.create([
        "border-color",
        "box-shadow",
      ]),
      border: (props) =>
        props.noBorder ? null : "1px solid #ced4da",
      fontSize: 15,
    },
  },
  IconButton: {
    padding: "5px",
  },
  icon: {
    fontSize: "20px",
    color: "#888",
  },
}))
