import React from "react"
import { Avatar, makeStyles } from "@material-ui/core"
import { Image } from "@material-ui/icons"

const ResponsiveImage = ({
  src,
  size,
  maxWidth,
  ...rest
}) => {
  const classes = useStyles({ size, maxWidth })

  return (
    <Avatar
      src={src}
      variant="rounded"
      className={classes.image}
    >
      <Image className={classes.icon} />
    </Avatar>
  )
}

export default ResponsiveImage

const useStyles = makeStyles((theme) => ({
  image: {
    height: (props) => props.size,
    width: (props) => props.size,
    maxWidth: (props) => props.maxWidth,
  },
  icon: {
    height: (props) => props.size / 3,
    width: (props) => props.size / 3,
  },
}))
