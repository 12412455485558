import React from "react"
import {
  Button,
  Checkbox,
  Link,
  TableRow,
  TableCell,
  makeStyles,
} from "@material-ui/core"
import { TextCell } from "components"

const SearchItem = ({
  user,
  selectedIds,
  handleSelect,
  handleClick,
  ...props
}) => {
  const classes = useStyles()
  const isSelected = selectedIds?.includes(user.id)

  return (
    <TableRow className={classes.root}>
      <TableCell>
        <div className={classes.cell}>
          <Checkbox
            checked={isSelected ? true : false}
            onChange={() => handleSelect(user)}
            name="user"
            color="primary"
          />
          <Link href={`/players/${user.id}`}>
            {user.name}
          </Link>
        </div>
      </TableCell>
      <TextCell>{user.country}</TextCell>
      <TextCell>
        {user?.positions?.join(",")}
      </TextCell>
      <TextCell>{user?.preferred_foot}</TextCell>
      <TextCell>{user.date_of_birth}</TextCell>
      <TextCell>{user?.height}</TextCell>
      <TextCell>{user?.weight}</TextCell>
      <TableCell>
        <div className={classes.tableButtons}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleClick(user)}
          >
            View Player
          </Button>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default SearchItem

const useStyles = makeStyles((theme) => ({
  root: {},
  tableButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}))
