import palette from "../palette"
import typography from "../typography"

export default {
  root: {
    padding: "2px 8px 2px 8px",
    ...typography.body2,
    borderBottom: `1px solid ${palette.common.tableBorder}`,
    borderRight: `1px solid ${palette.common.tableBorder}`,
  },
}
