import { isEmpty } from "validations"

export const validateTeam = (team) => {
  let isValid = true
  let messages = []

  if (isEmpty(team.name)) {
    isValid = false
    messages.push("Name is required")
  }

  if (isEmpty(team.gender)) {
    isValid = false
    messages.push("Gender is required")
  }

  if (isEmpty(team.age_group)) {
    isValid = false
    messages.push("Age group is required")
  }

  if (isEmpty(team.league)) {
    isValid = false
    messages.push("League is required")
  }

  if (isEmpty(team.division)) {
    isValid = false
    messages.push("Division is required")
  }

  let validation = {
    isValid,
    messages,
  }

  return validation
}
