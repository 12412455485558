import { colors } from "@material-ui/core"

export default {
  root: {
    textAlign: "left",
    borderRadius: 4,
    padding: "3px 16px",
  },
  contained: {
    padding: "3px 16px",
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.14)",
    "&:hover": {},
  },
  outlined: {
    height: 32,
  },
}
