import palette from "./palette"

export default {
  fontFamily: [
    "Tungsten Bold",
    "Proximanova Medium",
    "Proximanova Regular",
    "Proximanova Semibold",
    "Tungsten Bold",
    "Tungsten Semibold",
    "Roboto",
  ].join(","),
  h1: {
    fontFamily: "Proximanova Medium",
    fontWeight: 600,
    fontSize: 120,
    lineHeight: "0.8em",
    textTransform: "uppercase",
    "@media (max-width:600px)": {
      fontSize: 62,
    },
  },
  h2: {
    fontFamily: "Proximanova Medium",
    fontWeight: 600,
    fontSize: 44,
    textTransform: "uppercase",
    "@media (max-width:600px)": {
      fontSize: 38,
    },
    lineHeight: "0.8em",
  },
  h3: {
    fontFamily: "Proximanova Medium",
    fontWeight: 600,
    fontSize: 38,
    textTransform: "uppercase",
    "@media (max-width:600px)": {
      fontSize: 32,
    },
    lineHeight: "1.3em",
  },
  h4: {
    fontFamily: "Proximanova Medium",
    fontWeight: 600,
    textIndent: 0,
    fontSize: 28,
    textTransform: "uppercase",
    lineHeight: "1.3em",
  },
  h5: {
    fontFamily: "Proximanova Medium",
    fontWeight: 600,
    fontSize: 18,
    textTransform: "uppercase",
    lineHeight: "1.3em",
  },
  h6: {
    fontFamily: "Proximanova Medium",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "1em",
  },
  subtitle1: {
    fontFamily: "Proximanova Medium",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "1.5em",
  },
  subtitle2: {
    fontFamily: "Proximanova Medium",
    fontWeight: 500,
    fontSize: 15,
    lineHeight: "2.0em",
  },
  subtitle3: {
    fontFamily: "Tungsten Bold",
    fontWeight: 600,
    textIndent: 0,
    fontSize: 20,
    textTransform: "uppercase",
    lineHeight: "1.3em",
  },
  body1: {
    fontFamily: "Proximanova Medium",
    fontWeight: 500,
    color: palette.text.primary,
    fontSize: 15,
    lineHeight: "1.5em",
  },
  body2: {
    fontFamily: "Proximanova Medium",
    color: palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "1.75em",
    textDecoration: "none",
  },
  button: {
    fontFamily: "Proximanova Medium",
    color: palette.text.primary,
    fontSize: 12,
    fontWeight: 600,
  },
  caption: {
    fontFamily: "Proximanova Medium",
    fontSize: 12,
    textTransform: "uppercase",
    fontWeight: 500,
  },
  overline: {
    fontFamily: "Proximanova Medium",
    color: palette.text.secondary,
    fontSize: 14,
    letterSpacing: 0.01071,
    fontWeight: 400,
    lineHeight: "13px",
    textTransform: "none",
  },
}
