import React from "react"
import { Chip, makeStyles } from "@material-ui/core"

const Label = ({ label, ...rest }) => {
  const classes = useStyles()
  return (
    <Chip className={classes.chip} label={label} />
  )
}

export default Label

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(1),
  },
}))
