import { isEmpty } from "validations"

export const validateClub = (club) => {
  let isValid = true
  let messages = []

  if (isEmpty(club.name)) {
    isValid = false
    messages.push("Name is required")
  }

  if (isEmpty(club.city)) {
    isValid = false
    messages.push("City is required")
  }

  if (isEmpty(club.country)) {
    isValid = false
    messages.push("Country is required")
  }

  if (isEmpty(club.training_ground)) {
    isValid = false
    messages.push(
      "Training ground address is required"
    )
  }

  if (isEmpty(club.main_ground)) {
    isValid = false
    messages.push("Main ground address is required")
  }

  let validation = {
    isValid,
    messages,
  }

  return validation
}
