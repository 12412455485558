import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import {
  useSelected,
  useUsers,
  useAlerts,
} from "hooks"
import { Button, makeStyles } from "@material-ui/core"
import { Star } from "@material-ui/icons"
import {
  Autosuggest,
  Pagination,
  ButtonLoader,
  TableHeader,
  Subheader,
} from "components"
import FavoritesList from "containers/favorites/FavoritesList"
import { FAVORITE_BULK_ACTIONS } from "lib/constants"

const FavoritesContainer = ({ ...props }) => {
  const history = useHistory()
  const classes = useStyles()
  const { showAlertSuccess, showAlertError } =
    useAlerts()
  const [activeAction, setActiveAction] =
    useState(null)

  const { selectedIds, handleSelect, handleClear } =
    useSelected()

  const {
    isLoading,
    query,
    users,
    findFavoriteUsers,
    removeFavorites,
    handleSort,
    sortKey,
    sortDirection,
    page,
    numPages,
    paginate,
  } = useUsers({})

  const handleBulkAction = async () => {
    if (!activeAction) {
      showAlertError("Please select a bulk action")
      return
    }

    if (!selectedIds?.length > 0) {
      showAlertError("Select at least 1 player")
      return
    }
    let resp
    switch (activeAction) {
      case "remove_favorites":
        await removeFavorites(selectedIds)
        showAlertSuccess("Players have been unlisted")
        setActiveAction(null)
        handleClear()
        findFavoriteUsers()
        return
    }
  }

  const handleProfileClick = (user) => {
    history.push(`/players/${user.id}`)
  }

  useEffect(() => {
    findFavoriteUsers({
      ...query,
      sort_key: sortKey,
      sort_direction: sortDirection,
    })
  }, [sortKey, sortDirection])

  return (
    <>
      <Subheader
        icon={Star}
        title={"Listed Players"}
      />
      <div className={classes.userList}>
        <TableHeader>
          <div className={classes.bulkActions}>
            <Autosuggest
              value={activeAction}
              options={FAVORITE_BULK_ACTIONS}
              placeholder={"Bulk actions"}
              handleChange={(ev) =>
                setActiveAction(ev.target.value)
              }
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleBulkAction}
              className={classes.bulkActionButton}
            >
              Apply
            </Button>
          </div>
          <div>
            <Pagination
              page={page}
              numPages={numPages}
              handleChangePage={paginate}
            />
          </div>
        </TableHeader>
        <FavoritesList
          users={users}
          sortKey={sortKey}
          sortDirection={sortDirection}
          handleSort={handleSort}
          handleClick={handleProfileClick}
          selectedIds={selectedIds}
          handleSelect={handleSelect}
        />
      </div>
    </>
  )
}

export default FavoritesContainer

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  userList: {
    padding: theme.spacing(0),
  },
  bulkActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  bulkActionButton: {
    marginLeft: theme.spacing(1),
  },
}))
