import React, { useEffect, useState } from "react"
import { useAuth } from "hooks"
import {
  List,
  ListItem,
  ListItemText,
  AppBar,
  Button,
  Toolbar,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core"
import Logo from "assets/logo.svg"
import { useLocation, useHistory } from "react-router"
import { isClub, isScout } from "lib/utils"
import { MENU_ITEMS } from "lib/navigation"
import SettingsModal from "containers/settings/SettingsModal"
import SearchAutocomplete from "containers/search/SearchAutocomplete"
import clsx from "clsx"

const Header = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location

  const { authenticated, fetchMe, currentUser } =
    useAuth()

  const [openSettings, setOpenSettings] =
    useState(false)

  const handleClick = (item) => {
    switch (item.value) {
      case "settings":
        setOpenSettings(true)
        break
      default:
        history.push(item.value)
        break
    }
  }

  const handleLogoClick = () => {
    if (currentUser?.id) {
      if (currentUser.role == "scout") {
        history.push("/favorites")
      } else {
        history.push("/clubs")
      }
    } else {
      history.push("/login")
    }
  }

  useEffect(() => {
    if (authenticated && !currentUser) {
      fetchMe()
    }
  }, [authenticated, currentUser])

  return (
    <div>
      <AppBar
        className={classes.appBar}
        position="fixed"
        elevation={0}
      >
        {currentUser?.id && (
          <Toolbar
            variant="dense"
            className={classes.toolbar}
          >
            <div className={classes.searchBar}>
              <div>
                <Button onClick={handleLogoClick}>
                  <img
                    src={Logo}
                    className={classes.logo}
                  />
                </Button>
              </div>
              <div
                className={classes.searchContainer}
              >
                <SearchAutocomplete />
              </div>
            </div>
            <div className={classes.menuItems}>
              {MENU_ITEMS.map((item, index) => {
                let Icon = item.icon
                const clubUser = isClub(currentUser)
                return (item.require_club &&
                  clubUser) ||
                  !item.require_club ? (
                  <IconButton
                    key={index}
                    onClick={() => handleClick(item)}
                    className={classes.menuItem}
                  >
                    <Icon
                      className={clsx(classes.icon, {
                        [classes.selected]:
                          pathname === item.value,
                      })}
                    />
                  </IconButton>
                ) : null
              })}
              <List className={classes.accountList}>
                <ListItem
                  button
                  onClick={() =>
                    setOpenSettings(true)
                  }
                  className={classes.accountList}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        className={classes.username}
                      >
                        {currentUser?.name}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="body2"
                        className={classes.userrole}
                      >
                        {currentUser?.role}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </div>
          </Toolbar>
        )}
      </AppBar>
      <SettingsModal
        open={openSettings}
        handleClose={() => setOpenSettings(false)}
      />
    </div>
  )
}

export default Header

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.main,
    overflow: "auto",
  },
  searchBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  searchContainer: {
    marginLeft: theme.spacing(2),
  },
  menuItems: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  box: {
    display: "flex",
    flexDirection: "row",
  },
  icon: {
    color: theme.palette.common.white,
  },
  selected: {
    color: theme.palette.secondary.main,
  },
  logo: {
    width: 180,
  },
  accountList: {
    padding: theme.spacing(0, 1),
  },
  username: {
    lineHeight: 1,
    fontWeight: "bold",
    color: theme.palette.common.white,
  },
  userrole: {
    color: theme.palette.common.white,
    lineHeight: 1,
    textTransform: "capitalize",
  },
}))
