import React from "react"
import {
  Hidden,
  Grid,
  Link,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { useHistory } from "react-router"
import {
  Instagram,
  Twitter,
  YouTube,
  Facebook,
} from "@material-ui/icons"
import FooterDetail from "assets/footer-detail.png"

const SOCIAL_ICONS = [
  {
    value:
      "https://www.instagram.com/ellevate.football",
    icon: Instagram,
  },
  {
    value: "https://twitter.com/ellevateapp_",
    icon: Twitter,
  },
  {
    value:
      "https://www.facebook.com/EllevateFootball",
    icon: Facebook,
  },
  {
    value:
      "https://www.youtube.com/channel/UCmQHUh7NOMjW4udbdTyslIA",
    icon: YouTube,
  },
]

const Footer = () => {
  const classes = useStyles()

  const history = useHistory()

  const handleSocialClick = (url) => {
    window.open(url, "_blank")
  }

  const handleURLClick = (url) => {
    window.open(url, "_blank")
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Hidden smDown>
            <div className={classes.copyright}>
              <Typography
                variant="body2"
                className={classes.caption}
              >
                &copy; Copyright 2020 Ellevate
                Football | Founder Akshay Lugani
              </Typography>
            </div>
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className={classes.socialIcons}>
            {SOCIAL_ICONS.map(
              ({ value, icon: Icon }, index) => (
                <IconButton
                  size="small"
                  key={index}
                  className={classes.iconButton}
                  onClick={() =>
                    handleSocialClick(value)
                  }
                >
                  <Icon className={classes.icon} />
                </IconButton>
              )
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Hidden smDown>
            <div className={classes.legal}>
              <Link
                onClick={() =>
                  handleURLClick(
                    "https://ellevate-football.co.uk/terms-conditions"
                  )
                }
                variant="body2"
                className={classes.link}
              >
                Terms & Conditions
              </Link>
              <Link
                onClick={() =>
                  handleURLClick(
                    "https://ellevate-football.co.uk/privacy-policy"
                  )
                }
                variant="body2"
                className={classes.link}
              >
                Privacy Policy
              </Link>
            </div>
          </Hidden>
        </Grid>
      </Grid>
    </div>
  )
}

export default Footer

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 48,
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.light,
    background: `url(${FooterDetail}) left center no-repeat`,
  },
  copyright: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  caption: {
    color: theme.palette.common.white,
  },
  legal: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  socialIcons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    margin: theme.spacing(1),
  },
  iconButton: {
    padding: 0,
  },
  link: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
}))
