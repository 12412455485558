import React from "react"
import { Route } from "react-router"
import { Switch } from "react-router-dom"
import { AuthRoute } from "components"

//auth
import Login from "containers/auth/Login"
import Signup from "containers/auth/Signup"
import ForgotPassword from "containers/auth/ForgotPassword"
import ResetPassword from "containers/auth/ResetPassword"
import VerifyPin from "containers/auth/VerifyPin"

//players
import PlayerShow from "containers/players/PlayerShow"
import FavoritesContainer from "containers/favorites/FavoritesContainer"
import SearchContainer from "containers/search/SearchContainer"
import DiscoverContainer from "containers/discover/DiscoverContainer"
import ClubShow from "containers/clubs/ClubShow"

const Routes = () => (
  <Switch>
    <Route path="/" component={Login} exact />
    <Route path="/login/:token" component={Login} />
    <Route path="/login" component={Login} />
    <Route path="/signup" component={Signup} />
    <Route
      path="/forgot_password"
      component={ForgotPassword}
      exact
    />
    <Route
      path="/verify_pin"
      component={VerifyPin}
      exact
    />
    <Route
      path="/reset_password"
      component={ResetPassword}
      exact
    />
    <Route
      path="/users/passwords/edit"
      component={ResetPassword}
      exact
    />

    <AuthRoute
      path="/search"
      component={SearchContainer}
      exact
    />
    <AuthRoute
      path="/favorites"
      component={FavoritesContainer}
      exact
    />
    <AuthRoute
      path="/discover"
      component={DiscoverContainer}
      exact
    />

    <AuthRoute
      path="/players/:id"
      component={PlayerShow}
      exact
    />

    <AuthRoute
      path="/clubs"
      component={ClubShow}
      exact
    />
  </Switch>
)

export default Routes
