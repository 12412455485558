import React from "react"
import { Autosuggest, TextInput } from "components"
import { Button, Grid } from "@material-ui/core"
import { COUNTRIES } from "lib/constants/countries"
import { USER_ROLES } from "lib/constants"

const SignupForm = ({
  user,
  step = 1,
  handleKeyPress,
  handleChange,
  handleSubmit,
}) => (
  <Grid container spacing={2}>
    {step === 1 ? (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <Autosuggest
            label="Country"
            options={COUNTRIES}
            value={user.country}
            name="country"
            placeholder="COUNTRY"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Autosuggest
            label="Role"
            options={USER_ROLES}
            value={user.role}
            name="role"
            placeholder="ROLE"
            handleChange={handleChange}
          />
        </Grid>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {user.role == "club" && (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <TextInput
                label="Club Name"
                name="club_name"
                value={user.club_name}
                onKeyPress={handleKeyPress}
                placeholder="CLUB NAME"
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autosuggest
                label="Club Country"
                options={COUNTRIES}
                value={user.club_country}
                name="club_country"
                placeholder="CLUB COUNTRY"
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextInput
                label="Club City"
                value={user.club_city}
                name="club_city"
                placeholder="CLUB CITY"
                handleChange={handleChange}
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12} sm={12}>
          <TextInput
            label="First name"
            name="first_name"
            value={user.first_name}
            onKeyPress={handleKeyPress}
            placeholder="FIRST NAME"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            label="Surname"
            name="last_name"
            value={user.last_name}
            onKeyPress={handleKeyPress}
            placeholder="SURNAME"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            label={"Email Address"}
            required={true}
            name="email"
            value={user.email}
            onKeyPress={handleKeyPress}
            placeholder="EMAIL"
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            label="Password"
            name="password"
            value={user.password}
            type="password"
            onKeyPress={handleKeyPress}
            placeholder="PASSWORD"
            handleChange={handleChange}
          />
        </Grid>
      </React.Fragment>
    )}
    <Grid item xs={12} sm={12}>
      <center>
        <Button
          fullWidth
          size="large"
          color="secondary"
          onClick={handleSubmit}
          variant="contained"
        >
          {step === 1 ? "Next" : "Create Account"}
        </Button>
      </center>
    </Grid>
  </Grid>
)

export default SignupForm
