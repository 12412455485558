import React from "react"
import {
  Button,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
  makeStyles,
} from "@material-ui/core"

const TeamItem = ({
  team,
  selectedIds = [],
  handleSelect,
  handleClick,
  ...props
}) => {
  const classes = useStyles()
  const isSelected = selectedIds.includes(team.id)

  return (
    <TableRow>
      <TableCell>
        <div className={classes.cell}>
          <Checkbox
            checked={isSelected ? true : false}
            onChange={() => handleSelect(team)}
            name="user"
            color="primary"
          />
          <Typography variant="body2">
            {team.name}
          </Typography>
        </div>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {team.gender}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {team.age_group}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {team.league}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {team.division}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleClick(team)}
        >
          View Players
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default TeamItem

const useStyles = makeStyles((theme) => ({
  cell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}))
