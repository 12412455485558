import React from "react"
import {
  Button,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { PlayArrow } from "@material-ui/icons"
import { ResponsiveImage } from "components"
import { resize } from "lib/cloudinary"
import {
  THUMBNAIL_HEIGHT,
  THUMBNAIL_WIDTH,
} from "lib/constants"

const VideoItem = ({
  video,
  handleClick,
  width = THUMBNAIL_WIDTH,
  height = THUMBNAIL_HEIGHT,
  renderUserName = false,
  isLocked,
  ...props
}) => {
  const classes = useStyles()

  const imageUrl = resize(video.image_url, {
    width: width,
    height: height,
  })

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        onClick={() => handleClick(video)}
      >
        <div className={classes.image}>
          <div className={classes.iconContainer}>
            <PlayArrow className={classes.icon} />
          </div>
          <ResponsiveImage
            src={imageUrl}
            borderRadius
            minHeight={240}
            width={width}
          />
        </div>
      </Button>
      {renderUserName && (
        <Link href={`/players/${video.user_id}`}>
          <Typography
            className={classes.name}
            variant="body2"
          >
            {video?.user?.name}
          </Typography>
        </Link>
      )}
    </div>
  )
}

export default VideoItem

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 140,
    minWidth: 140,
  },
  caption: {
    textTransform: "uppercase",
  },
  button: {
    padding: 0,
  },
  name: {
    fontWeight: 600,
  },
  image: {
    width: "100%",
    overflow: "hidden",
  },
  label: {
    position: "absolute",
    zIndex: 999,
    padding: theme.spacing(0, 1),
    borderRadius: 20,
    margin: theme.spacing(2),
    backgroundColor: "#CDCECF",
    color: theme.palette.background.main,
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 999,
    width: "100%",
    height: "100%",
  },
  icon: {
    height: 60,
    width: 60,
    color: theme.palette.common.white,
  },
}))
