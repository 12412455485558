import React from "react"
import {
  IconButton,
  Button,
  makeStyles,
  TableCell,
  Typography,
} from "@material-ui/core"
import {
  UnfoldMore,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons"

const SortableTableHeader = ({
  value,
  sortKey,
  sortable = true,
  sortDirection,
  handleSort,
  children,
}) => {
  let isActive = sortKey === value ? true : false

  const classes = useStyles()

  const renderIcon = () => {
    if (!sortable) {
      return null
    }
    if (isActive) {
      if (sortDirection === "asc") {
        return <ExpandLess className={classes.icon} />
      } else {
        return <ExpandMore className={classes.icon} />
      }
    } else {
      return <UnfoldMore className={classes.icon} />
    }
  }

  return (
    <TableCell
      className={classes.tableCell}
      align="left"
      sortDirection="asc"
    >
      <div className={classes.cell}>
        <Typography
          variant="subtitle1"
          className={classes.label}
        >
          {children}
        </Typography>
        {sortable && (
          <IconButton
            size="small"
            onClick={() =>
              sortable && handleSort(value)
            }
          >
            {renderIcon()}
          </IconButton>
        )}
      </div>
    </TableCell>
  )
}

export default SortableTableHeader

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 13,
    justifyContent: "left",
    textTransform: "uppercase",
    padding: theme.spacing(1),
    fontWeight: "bold",
  },
  tableCell: {
    padding: 0,
  },
  cell: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    height: 18,
    width: "auto",
  },
}))
