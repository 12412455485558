import {
  isEmail,
  isEmpty,
  isMinLength,
  isWorkEmail,
} from "validations"

export const validateUser = (user) => {
  let isValid = true
  let messages = []

  console.log("VALIDATE", user)

  if (
    isEmpty(user.first_name) ||
    isEmpty(user.last_name)
  ) {
    isValid = false
    messages.push("Full name is required")
  }

  if (isEmpty(user.role)) {
    isValid = false
    messages.push("Club or Scout role is required")
  }

  if (user.role == "club") {
    if (isEmpty(user.club_name)) {
      isValid = false
      messages.push("Club name is required")
    }

    if (isEmpty(user.club_country)) {
      isValid = false
      messages.push("Club country is required")
    }

    if (isEmpty(user.club_city)) {
      isValid = false
      messages.push("Club city is required")
    }
  }

  if (!isEmail(user.email)) {
    isValid = false
    messages.push("Email is not a valid email")
  }

  if (isEmpty(user.email)) {
    isValid = false
    messages.push("Email is required")
  }

  if (!isMinLength(user.email, 4)) {
    isValid = false
    messages.push(
      "Email must be at least 4 characters"
    )
  }

  if (isEmpty(user.password)) {
    isValid = false
    messages.push("Password is required")
  }

  if (!isMinLength(user.password, 4)) {
    isValid = false
    messages.push(
      "Password must be at least 4 characters"
    )
  }

  let validation = {
    isValid,
    messages,
  }

  return validation
}

export const validatePassword = (
  password,
  passwordConfirmation
) => {
  let isValid = true
  let messages = []

  if (
    isEmpty(password) ||
    isEmpty(passwordConfirmation)
  ) {
    isValid = false
    messages.push(
      "Password and password confirmation are required"
    )
  }

  if (!isMinLength(password, 4)) {
    isValid = false
    messages.push(
      "Password must be at least 4 characters"
    )
  }

  if (password != passwordConfirmation) {
    isValid = false
    messages.push("Passwords do not match")
  }

  let validation = {
    isValid,
    messages,
  }

  return validation
}
