import React from "react"
import {
  makeStyles,
  Typography,
} from "@material-ui/core"
import { COUNTRIES } from "lib/constants/countries"

const FlagIcon = ({ code, variant = "body2" }) => {
  const classes = useStyles()
  const countryCode = code?.toLowerCase()
  const country = COUNTRIES.find(
    (c) => c.value === code
  )?.label
  return code ? (
    <div className={classes.root}>
      <img
        className={classes.flag}
        src={`https://flagcdn.com/w20/${countryCode}.png`}
      />
      <Typography variant={variant}>
        {country}
      </Typography>
    </div>
  ) : (
    "-"
  )
}

export default FlagIcon

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  flag: {
    margin: theme.spacing(0, 1),
  },
}))
