import React, { useEffect } from "react"
import { useVideos } from "hooks"
import {
  Typography,
  makeStyles,
} from "@material-ui/core"
import VideoGallery from "containers/videos/VideoGallery"

const VideoList = ({ userId, ...props }) => {
  const classes = useStyles()

  const {
    isLoading,
    videos,
    findVideos,
    paginate,
    page,
    perPage,
    totalCount,
  } = useVideos({
    userId: userId,
  })

  useEffect(() => {
    if (userId) {
      findVideos({
        "videos.user_id": userId,
      })
    }
  }, [userId])

  return (
    <div>
      <div className={classes.header}>
        <Typography
          variant="h5"
          className={classes.title}
        >
          HIGHLIGHTS
        </Typography>
      </div>
      <VideoGallery videos={videos} />
    </div>
  )
}

export default VideoList

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    margin: theme.spacing(1, 0),
  },
  title: {},
}))
