import React from "react"
import {
  Home,
  Search,
  Explore,
  Star,
  Settings,
} from "@material-ui/icons"

export const MENU_ITEMS = [
  {
    value: "/clubs",
    label: "Home",
    icon: Home,
    require_club: true,
  },
  {
    value: "/search",
    label: "Search",
    icon: Search,
    require_club: false,
  },
  {
    value: "/favorites",
    label: "Listed",
    icon: Star,
    require_club: false,
  },
  {
    value: "/discover",
    label: "Expore",
    icon: Explore,
    require_club: false,
  },
  {
    value: "settings",
    label: "Settings",
    icon: Settings,
    require_club: false,
  },
]

export const SETTINGS = [
  { value: "privacy", label: "PRIVACY" },
  { value: "terms", label: "TERMS & CONDITIONS" },
  { value: "log_out", label: "LOG OUT" },
]
