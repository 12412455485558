import { colors } from "@material-ui/core"

export default {
  primary: {
    contrastText: "#FFFFFF",
    main: "#151515",
  },
  secondary: {
    contrastText: "#000000",
    main: "#3DCD90",
    light: "#6AE4A4",
  },
  error: {
    contrastText: "#ffffff",
    dark: colors.pink[900],
    main: colors.pink[600],
    light: colors.pink[400],
  },
  text: {
    primary: "#151515",
    secondary: "#191919",
    link: "#CED9E4",
  },
  link: "#23263B",
  icon: "#1E1E1E",
  background: {
    main: "#000000",
    light: "#1E1E1E",
    default: "#000000",
    paper: "#000000",
    table: "#EEEEEE",
  },
  common: {
    border: "#838383",
    inputBorder: "#ced4da",
    grey: "#707070",
    input: "#000000",
    tableBorder: "#C9C9C9",
  },
  divider: colors.grey[200],
}
