import React from "react"
import {
  Link,
  Button,
  TableRow,
  TableCell,
  makeStyles,
} from "@material-ui/core"
import { FlagIcon, TextCell } from "components"

const PlayerItem = ({
  user,
  handleClick,
  ...props
}) => {
  const classes = useStyles()

  return (
    <TableRow className={classes.root}>
      <TextCell>
        <Link href={`/players/${user.id}`}>
          {user.name}
        </Link>
      </TextCell>
      <TextCell>
        <FlagIcon code={user.country} />
      </TextCell>
      <TextCell>
        {user?.positions?.join(",")}
      </TextCell>
      <TextCell>{user?.preferred_foot}</TextCell>
      <TextCell>{user.date_of_birth}</TextCell>
      <TextCell>{user?.height}</TextCell>
      <TextCell>{user?.weight}</TextCell>
      <TableCell>
        <div className={classes.tableButtons}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleClick(user)}
          >
            View Player
          </Button>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default PlayerItem

const useStyles = makeStyles((theme) => ({
  root: {},
  tableButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))
