import React, { useEffect, useState } from "react"
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core"
import { Modal } from "components"
import ReactJWPlayer from "react-jw-player"
import { useHistory } from "react-router-dom"

const VideoModal = ({
  open,
  src,
  title = "VIDEO HIGHLIGHTS",
  handleClose,
  autoPlay = true,
  video,
  ...props
}) => {
  const classes = useStyles()
  const history = useHistory()

  const [completed, setCompleted] = useState(false)

  const playlist = [
    {
      file: src,
    },
  ]

  const handleOnTimeEvent = async (
    playerProgress
  ) => {
    const { duration, currentTime } = playerProgress
    if (!completed && currentTime / duration > 0.99) {
      handleClose()
      setCompleted(true)
    }
  }

  const handlePlayerClick = () => {
    history.push(`/players/${video.user_id}`)
  }

  useEffect(() => {
    if (!open) {
      setCompleted(false)
    }
  }, [open])

  return (
    <Modal
      padding={0}
      open={open}
      handleClose={handleClose}
      title={title}
      maxWidth={"lg"}
    >
      {src && (
        <center>
          <ReactJWPlayer
            playerId="12345"
            playlist={playlist}
            isAutoPlay
            onTime={handleOnTimeEvent}
          />
        </center>
      )}
      {video && (
        <List>
          <ListItem
            button
            onClick={handlePlayerClick}
          >
            <ListItemText
              primary={video?.user?.name}
            />
          </ListItem>
        </List>
      )}
    </Modal>
  )
}

export default VideoModal

const useStyles = makeStyles((theme) => ({
  root: {},
  video: {},
}))
