import React from "react"
import {
  TableRow,
  TableCell,
  Link,
  makeStyles,
} from "@material-ui/core"
import {
  Autosuggest,
  TextCell,
  FlagIcon,
} from "components"
import { USER_APPROVALS } from "lib/constants"
import TeamAutosuggest from "containers/teams/TeamAutosuggest"

const PendingUserItem = ({
  user,
  handleChange,
  ...props
}) => {
  const classes = useStyles()

  const handleUserChange = (ev) => {
    handleChange(ev, user?.id)
  }

  return (
    <TableRow className={classes.root}>
      <TextCell>
        <Link href={`/players/${user.id}`}>
          {user.name}
        </Link>
      </TextCell>
      <TextCell>
        <FlagIcon code={user.country} />
      </TextCell>
      <TextCell>{user.date_of_birth}</TextCell>
      <TableCell>
        <Autosuggest
          noBorder
          name="club_approved"
          placeholder="APPROVE ▼"
          options={USER_APPROVALS}
          value={user?.club_approved}
          handleChange={handleUserChange}
        />
      </TableCell>
      <TableCell>
        <TeamAutosuggest
          name="team_id"
          value={user?.team_id}
          handleChange={handleUserChange}
        />
      </TableCell>
    </TableRow>
  )
}

export default PendingUserItem

const useStyles = makeStyles((theme) => ({
  root: {},
  cell: {},
  icon: {
    height: 18,
    width: 18,
  },
}))
