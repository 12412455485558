import React from "react"
import PropTypes from "prop-types"
import { Paper, makeStyles } from "@material-ui/core"

const LeftPanel = ({
  title,
  width = 400,
  subtitle,
  children,
  ...props
}) => {
  const classes = useStyles({
    width,
  })

  return (
    <div className={classes.root}>
      <div className={classes.paper}>{children}</div>
    </div>
  )
}

LeftPanel.propTypes = {
  children: PropTypes.any.isRequired,
}

export default LeftPanel

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.common.white,
    width: (props) => props.width,
    borderRight: `1px solid ${theme.palette.common.border}`,
  },
  paper: {
    background: theme.palette.common.white,
    padding: theme.spacing(3),
    height: "calc(100vh - 150px)",
    overflowY: "scroll",
  },
}))
