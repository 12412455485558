import React from "react"
import { useAuth } from "hooks"
import { AuthScreen } from "components"
import { useHistory } from "react-router"
import LoginForm from "containers/auth/LoginForm"
import {
  Typography,
  Button,
  Link,
  makeStyles,
} from "@material-ui/core"

const Login = ({ ...props }) => {
  const { user, handleChange, loginUser } = useAuth()
  const history = useHistory()
  const classes = useStyles()

  const handleSubmit = async () => {
    await loginUser(user)
  }

  const handleKeyPress = (event) => {
    if (event.key == "Enter") handleSubmit()
  }

  const handleSignupClick = () => {
    history.push("/signup")
  }

  return (
    <div>
      <AuthScreen
        actions={
          <>
            <Typography
              variant="body1"
              className={classes.actionText}
            >
              Already Have An Account?
            </Typography>
            <Button
              onClick={handleSignupClick}
              variant="contained"
              color="secondary"
            >
              Choose a Plan
            </Button>
          </>
        }
      >
        <LoginForm
          user={user}
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
          handleSubmit={handleSubmit}
        />
      </AuthScreen>
    </div>
  )
}

export default Login

const useStyles = makeStyles((theme) => ({
  actionText: {
    fontSize: 12,
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
}))
