import React from "react"
import { useHistory } from "react-router"
import { makeStyles } from "@material-ui/core"

const Page = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>{children}</div>
  )
}

export default Page

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
}))
