import React, { useContext } from "react"
import { AppContext } from "context"
import { useAlerts } from "hooks"
import { useHistory } from "react-router-dom"
import {
  SortableTableHeader,
  TableHeader,
} from "components"
import {
  Paper,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from "@material-ui/core"
import DiscoverPlayerItem from "containers/discover/DiscoverPlayerItem"

const TABLE_HEADERS = [
  {
    value: "name",
    label: "Full Name",
    sortable: true,
    hidden: false,
  },
  {
    value: "country",
    label: "Country",
    sortable: true,
    hidden: false,
  },
  {
    value: "positions",
    label: "Position",
    sortable: true,
    hidden: false,
  },
  {
    value: "preferred_foot",
    label: "Foot",
    sortable: true,
    hidden: false,
  },
  {
    value: "dob",
    label: "Date of Birth",
    sortable: true,
    hidden: false,
  },
]

const PlayerList = ({
  users,
  page,
  numPages,
  onChangePage,
  sortKey,
  sortDirection,
  handleSort,
  ...props
}) => {
  const history = useHistory()

  const { currentUser } = useContext(AppContext)
  const classes = useStyles()
  const { showAlertSuccess, showAlertError } =
    useAlerts()

  const handleClick = (user) => {
    history.push(`/players/${user.id}`)
  }

  return (
    <div>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow
              className={classes.tableHeaderRow}
            >
              {TABLE_HEADERS.filter(
                (h) => !h.hidden
              ).map((header, idx) => (
                <SortableTableHeader
                  key={idx}
                  handleSort={handleSort}
                  value={`users.${header.value}`}
                  sortable={header.sortable}
                  sortKey={sortKey}
                  sortDirection={sortDirection}
                >
                  {header.label}
                </SortableTableHeader>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users.map((user, idx) => (
                <DiscoverPlayerItem
                  key={idx}
                  user={user}
                  handleClick={handleClick}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default PlayerList

const useStyles = makeStyles((theme) => ({
  caption: {
    fontWeight: 600,
  },
  tableHeaderRow: {
    backgroundColor: theme.palette.background.table,
  },
}))
